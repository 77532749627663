import { getUser } from '@/services/auth.service'

const plan = {
  namespaced: true,
  state: {
    plans: [],
    fetchStart: null,
    fetchEnd: null,
    error: null
  },
  mutations: {
    ADD_PLANS (state, plans) {
      state.plans = [...state.plans, ...plans]
    },
    SET_PLANS (state, plans) {
      state.plans = plans
    },
    UPLOAD_START (state) {
      state.fetchStart = Date.now()
    },
    UPLOAD_END (state) {
      state.fetchEnd = Date.now()
    },
    UPLOAD_ERROR (state, error) {
      state.error = error
    },
    FETCH_START (state) {
      state.fetchStart = Date.now()
    },
    FETCH_END (state) {
      state.fetchEnd = Date.now()
    },
    FETCH_ERROR (state, error) {
      state.error = error
    },
  },
  actions: {
    async uploadPlans ({ commit }, plans) {
      commit('UPLOAD_START')
      try {
        const organisationId = getUser().organisationId
        const { data } = await this.$api.uploadPlansForOrganisation(plans, organisationId)
        commit('ADD_PLANS', data)
        commit('UPLOAD_END')
        return Promise.resolve()
      } catch (error) {
        commit('UPLOAD_ERROR', error)
        return Promise.reject(error)
      }
    },
    async fetchPlans ({ commit }) {
      commit('FETCH_START')
      try {
        const organisationId = getUser().organisationId
        let { data: plans } = await this.$api.fetchPlansForOrganisation(organisationId)
        plans = plans.filter(p => !p.archived)
        commit('SET_PLANS', plans)
        commit('FETCH_END')
        return Promise.resolve()
      } catch (err) {
        commit('FETCH_ERROR', { name: 'getPlans', err, date: Date.now() })
        return Promise.reject(err)
      }
    }
  },
  getters: {
    plans: state => [...state.plans]
  }
}

export default plan
