<template>
  <div class="upload-container">
    <SectionHeader title="Import AR Audits" subtitle="Plan details should be uploaded using the excel template link" />
    <div class="upload-container__dropbox" :class="{ 'is-active': dragEnabled }" @click="$refs['excel-import'].click()"
     @dragover.prevent
    @dragenter="dragEnabled = true"
    @dragleave="dragEnabled = false" 
    @drop="handleExcelUpload">
      <input @change="handleExcelUpload" accept=".xls, .xlsx" ref="excel-import" type="file" hidden />
      <p>
        Click here, or drag and drop your CSV or Excel spreadsheet in this box.
      </p>
    </div>
    <div class="button-row">
      <button :disabled="!uploadedPlans.length || saved" class="button" @click="save">
        Save
      </button>
      <button :disabled="!uploadedPlans.length" class="button secondary" @click="clearRows">
        Clear
      </button>
      <a class="button left" target="_blank" :href="`${publicPath}files/Annual Review Upload Template V3.2.xlsx`"
        download="Annual Review Upload Template V3.2.xlsx">
        Download Template
      </a>
    </div>
    <div class="ehcp-list">
      <div class="table-wrapper">
        <table class="table">
          <thead>
            <tr>
              <th>*C/YP ID</th>
              <th>Key Stage</th>
              <th>Primary Need</th>
              <th>Setting Name</th>
              <th>Setting Type</th>
              <th>Review Date</th>
              <th>Current Social Care</th>
              <th>SEN Case Officer</th>
              <th>Team/Region</th>
              <th>Is Historical</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(plan, index) in uploadedPlans" :key="index">
              <td>{{ plan.internalId }}</td>
              <td>{{ plan.keyStage }}</td>
              <td>{{ plan.primaryNeed }}</td>
              <td>{{ plan.settingName }}</td>
              <td>{{ plan.settingType }}</td>
              <td>{{ plan.finalisedDate | formatDate }}</td>
              <td>{{ plan.currentSocialCare }}</td>
              <td>{{ plan.caseOfficer }}</td>
              <td>{{ plan.teamRegion }}</td>
              <td>
                <input type="checkbox" v-model="plan.historical" :id="plan.internalId" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import XLSX from "xlsx"
import SectionHeader from "@/components/SectionHeader"
import DateFilter from "@/_mixins/date-filter.mixin"
import { mapActions } from "vuex"

export default {
  name: "PlanUpload",
  mixins: [DateFilter],
  components: { SectionHeader },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      dragEnabled: false,
      saved: false,
      template: {
        "Review Date": "finalisedDate",
        "Current Social Care": "currentSocialCare",
        "Internal ID": "internalId",
        "Key Stage": "keyStage",
        "Primary Need": "primaryNeed",
        "Setting Name": "settingName",
        "Setting Type": "settingType",
        "SEN Case Officer": "caseOfficer",
        "Team/Region": "teamRegion",
      },
      tableData: [],
    };
  },
  computed: {
    uploadedPlans() {
      return this.tableData
    },
  },
  methods: {
    ...mapActions({ uploadPlans: "plan/uploadPlans" }),
    
    handleExcelUpload(event) {
      event.preventDefault()
      this.tableData = []
      this.saved = false
     const spreadsheet = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0]
      const reader = new FileReader()
      reader.onload = (target) => {
        const data = target.target.result
        const workbook = XLSX.read(data, {
          type: "binary",
          cellDates: true,
          raw: false,
        })
        this.processSheet(workbook)
      }

      reader.readAsBinaryString(spreadsheet)
    },
    processSheet(workbook) {
      const workingSheetName = workbook.SheetNames[0]
      const workingSheet = workbook.Sheets[workingSheetName]
      const workingSheetJson = XLSX.utils.sheet_to_json(workingSheet)
      const mapped = []

      workingSheetJson.map((collection) => {
        let mappedRow = {}
        Object.keys(collection).forEach((key) => {
          const value = collection[key]
          const objKey = this.template[key]
          mappedRow = { ...mappedRow, [objKey]: value }
        })

        mappedRow.historical = false
        mapped.push(mappedRow)
        return mappedRow
      })

      this.tableData = mapped
    },
    save() {
      if (!this.tableData.length) {
        return alert("Please upload first.")
      }

      this.uploadPlans(this.tableData)
        .then(() => {
          alert("Plans uploaded successfully.")
          this.saved = true
        })
        .catch((err) =>
          alert(
            `Sorry, there was a problem uploading your plans. If the problem persists please contact support (msg: ${err.message}).`
          )
        )
    },
    clearRows() {
      this.tableData = []
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/sections.scss";

.upload-container {
  width: 100%;

  &__dropbox {
    align-items: center;
    border-radius: $border-radius;
    border: 1px dashed lightgrey;
    cursor: pointer;
    display: flex;
    font-size: 1.25rem;
    height: 100px;
    justify-content: center;
    margin: 1rem 0;
    padding: 1rem;
  }

  .button-row {
    display: flex;
    flex-direction: row-reverse;
  }

  .button.left {
    margin-right: auto;
  }
}

.is-active {
  background: #42ab983b;
}
</style>
