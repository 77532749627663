<template>
  <div class="review-assignment-container">
    <SectionHeader
      title="Review Assignment"
      subtitle="Click on a user to start assigning reviews."
    >
      <template v-slot:filters>
        <div class="dateFilter">
          From:
          <input type="date" name="search" v-model="dateRange.start" />
          To:
          <input type="date" name="search" v-model="dateRange.end" />
        </div>
      </template>
      <template v-slot:searchbar>
        <div class="search-bar field no-margin">
          <span class="icon input-icon">
            <i class="fas fa-search"></i>
          </span>
          <input
            type="text"
            class="input has-icon"
            placeholder="Search by name or email"
            v-model="searchTerm"
            @input="search"
          />
        </div>
      </template>
    </SectionHeader>
    <!-- List of Users -->
    <div class="review-list">
      <div class="info-row info-row--flush">
        <button
          class="button"
          :disabled="!selectedUser.id"
          @click="showAssignReviewModal()"
        >
          Assign Review
        </button>
      </div>
      <div class="table-wrapper">
        <table class="table" id="assignments-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Role / Position</th>
              <th># Assigned</th>
              <th># Not Completed</th>
              <th># Completed</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="user in filteredUsers"
              :key="user.id"
              @click="selectedUser = user"
              :class="{ selected: selectedUser.id === user.id }"
            >
              <td>{{ user.name }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.role }}</td>
              <td>{{ totalAssignments(user.id) }}</td>
              <td>{{ outstandingAssignments(user.id) }}</td>
              <td>{{ completedAssignments(user.id) }}</td>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td>
                <strong>Totals:</strong>
              </td>
              <td>
                {{ dateFilteredAssignments.length }}
              </td>
              <td>
                {{ dateFilteredAssignments.filter(a => !a.completedAt).length }}
              </td>
              <td>
                {{ dateFilteredAssignments.filter(a => a.completedAt).length }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Assign Review Modal -->
    <div
      class="modal"
      :class="{ 'is-active': isAssignReviewModalShowingShown }"
    >
      <div class="modal-background" @click.stop="hideAssignReviewModal()" />
      <div class="modal-content">
        <div class="modal-title">Assign Reviews</div>
        <div class="modal-body">
          <div class="field">
            <label>Name</label>
            <span class="icon input-icon">
              <i class="far fa-user"></i>
            </span>
            <input
              disabled
              type="text"
              class="input has-icon"
              v-model="selectedUser.name"
            />
          </div>
          <div class="field">
            <label>Email</label>
            <span class="icon input-icon">
              <i class="far fa-envelope"></i>
            </span>
            <input
              disabled
              type="text"
              class="input has-icon"
              v-model="selectedUser.email"
            />
          </div>
          <div class="results-container">
            <div class="input-container">
              <div class="field search-field">
                <span class="icon input-icon">
                  <i class="fa fa-search"></i>
                </span>
                <input
                  type="text"
                  class="input has-icon"
                  placeholder="Search by ID"
                  ref="searchInput"
                  v-model="searchPlansTerm"
                />
              </div>
            </div>
            <div class="unassigned">
              <PlanList
                :plans="plansList"
                :selected="selectedPlans"
                @plan-selected="assignPlan"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="button is-gradient" @click="assignReview">
            Assign
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlanList from '@/components/PlanList'
import SectionHeader from '@/components/SectionHeader'
import SearchUsersMixin from '@/_mixins/search-users.mixin'
import DateFilter from '@/_mixins/date-filter.mixin'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Assignments',
  mixins: [SearchUsersMixin, DateFilter],
  components: { PlanList, SectionHeader },
  data () {
    return {
      selectedUser: { id: null },
      isAssignReviewModalShowingShown: false,
      selectedPlans: [],
      searchPlansTerm: null,
      dateRange: {
        start: null,
        end: null
      },
    }
  },
  computed: {
    ...mapGetters({
      assignments: 'assignment/assignments',
      plans: 'plan/plans',
      users: 'organisation/users',
    }),
    plansList () {
      let plans = this.plans.sort((a, b) =>
        a.createdAt > b.createdAt ? -1 : 1
      )
      if (this.searchPlansTerm && this.searchPlansTerm.length > 0) {
        const searchParam = this.searchPlansTerm.toLowerCase()
        plans = plans.filter((plan) =>
          plan.internalId.toLowerCase().includes(searchParam)
        )
      }
      return plans
    },
    dateFilteredAssignments() {
      return this.assignments
        .filter(
            audit =>
              // Pass through if we don't have date start or completed at.
              !this?.dateRange?.start ||
              !audit?.createdAt ||
              // Or filter by them.
              new Date(audit.createdAt) >= this.fromDate
          )
          .filter(
            audit =>
              // Pass through if we don't have date end or completed at.
              !this?.dateRange?.end ||
              !audit?.createdAt ||
              // Or filter by them.
              new Date(audit.createdAt) <= this.toDate
          );
    },
    fromDate() {
      return new Date(this.dateRange.start)
    },
    toDate() {
      const date = new Date(this.dateRange.end)
      // Set time to EOD.
      date.setHours('23')
      date.setMinutes('59')
      date.setSeconds('59')
      return date
    },
    testing () {
      return 'Hello'
    },
  },
  methods: {
    ...mapActions({
      createAssignments: 'assignment/createAssignments',
      fetchAssignments: 'assignment/fetchAssignments',
    }),
    totalAssignments (userId) {
      return this.dateFilteredAssignments.filter((a) => a.userId === userId).length
    },
    outstandingAssignments (userId) {
      return this.dateFilteredAssignments.filter(
        (a) => a.userId === userId && !a.completedAt
      ).length
    },
    completedAssignments(userId) {
      // Filter audits...
      return (
        this.dateFilteredAssignments
          // By userId.
          .filter(audit => audit.userId === userId)
          // By complete status
          .filter(audit => audit.completedAt).length
      )
    },
    showAssignReviewModal () {
      this.isAssignReviewModalShowingShown = true
      this.$nextTick(() => this.$refs.searchInput.focus())
    },
    hideAssignReviewModal () {
      this.isAssignReviewModalShowingShown = false
      this.selectedUser = { id: null }
      this.selectedPlans = []
      this.searchPlansTerm = null
    },
    assignPlan (planId) {
      if (this.selectedPlans.find((a) => a.planId === planId)) {
        this.selectedPlans = this.selectedPlans.filter(
          (a) => a.planId !== planId
        )
        return
      }

      this.selectedPlans.push({ planId, userId: this.selectedUser.id })
    },
    assignReview () {
      if (!Object.keys(this.selectedPlans).length) {
        return alert('Nothing assigned')
      }

      try {
        this.createAssignments(this.selectedPlans)
        this.fetchAssignments()
        this.hideAssignReviewModal()
      } catch (error) {
        alert(error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/sections.scss";
@import "@/assets/styles/modal.scss";

.search-bar {
  max-width: 400px;
}

.search-field {
  margin: 0 !important;
}

.search-field input {
  width: 90%;
  outline: none;
  border: none;
}

.input-container {
  border-bottom: 1px solid lightgrey;
  width: 100%;
}

.results-container {
  border: 1px solid lightgrey;
  border-radius: $border-radius;
}

.unassigned {
  width: 100%;
  max-height: 500px;
  min-height: 320px;
  margin-bottom: 1rem;
  overflow-y: scroll;
}
</style>
