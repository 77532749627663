<template>
  <div class="login">
    <LoginForm @login="processLogin" @clear-error="clearError" :error="error" />
  </div>
</template>

<script>
import LoginForm from '@/components/forms/Login.vue'
import DefaultRouteMixin from '@/_mixins/default-route.mixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'login',
  mixins: [DefaultRouteMixin],
  components: { LoginForm },
  data () {
    return {
      error: null,
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  methods: {
    ...mapActions({ login: 'user/login' }),
    async processLogin (payload) {
      try {

        const { email, password } = payload
        
        if (!email || !password) {
          this.error = 'Please make sure both email and password are entered.'

          throw {
            name: 'ValidationError',
            message: 'Please make sure both email and password are entered.'
          }
        }

        // Call login action
        const { user } = await this.login({ email, password })

        if (user && user.twoFactorAuth) {
          return this.$router.push({ name: 'otpVerification', params: { message: 'A Verification Code has been sent to your email address.' } })
        } 

        this.navigateToDefaultRoute()
      } catch (error) {
        if (error.name === 'ValidationError') {
          this.error = error.message
        } else {
          this.error = 'There was an error logging in. Please try again.'
        }

        return
      }
    },
    clearError () {
      this.error = null
    }
  },
  mounted () {
  },
  beforeDestroy () {
    this.$off('login', this.processLogin)
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.login {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $brand-gradient;
}
</style>