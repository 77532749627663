<template>
  <div class="login">
    <otpVerificationForm
      :isError="isError" 
      :message="message" 
      :countDown="countDown" 
      :hasFailedVerification="hasFailedVerification"
      @otpVerificationSubmit="processVerificationCode" 
      @otpVerificationChange="handleVerificationChange" 
      @otpResend="otpResend" 
      @clear-error="clearError" 
    />
  </div>
</template>

<script>
import OtpVerificationForm from '@/components/forms/otpVerification/OtpVerificationForm.vue'
import DefaultRouteMixin from '@/_mixins/default-route.mixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'otpVerification',
  mixins: [DefaultRouteMixin],
  components: { OtpVerificationForm },
  data () {
    return {
      isError: false, // Error state
      message: 'Please check your email inbox for your verification code.', // Default message
      countDown: 60, // Seconds to disable resend button
      hasFailedVerification: false, // Failed verification state
      triesLeft: null, // Seconds to disable resend button
      previousOtp: null, // Previous OTP
      numberOfTries: 5, // Defined in the backend
      messages: {
        resend: 'Your verification code has been resent.',
        lastAttempt: 'This is your last attempt.',
        attemptsLeft: 'Attempts left: ',
        resendError: 'There was an error resending your verification code. Please try again.',
        invalidError: 'Invalid verification code. This is your last attempt.',
        incompleteError: 'Your verification code is incomplete.',
        updateError: 'You did not update your verification code.',
        failedError: 'You have failed verification. Please contact your administrator.',
        failedAttemptError: 'Invalid verification code.',
        error: 'There was an error verifying code. Please try again.',

      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    })
  },
  methods: {
    ...mapActions({ 
      hasOtpSession: 'user/hasOtpSession',
      verifyOtpCode: 'user/verifyOtpCode',
      resendOtpCode: 'user/resendOtpCode',
    }),
    async otpResend () {
      try {
        const hasOtpSession = await this.hasOtpSession()

        if (!hasOtpSession) {
          return this.$router.push({ name: 'login' })
        }

        const { successInd } = await this.resendOtpCode()

        if (successInd) {
          this.isError = false
          this.message = this.messages.resend

          return
        }
      } catch (error) {
        this.isError = true
        this.message = this.messages.resendError
      }
    },
    async processVerificationCode (otpObject) {
      try {
        const { otp, isOtpComplete } = otpObject
    
        if (!isOtpComplete) {
          
          this.isError = true
          this.message = this.messages.incompleteError

          return
        }

        
        if (otp === this.previousOtp) {
          this.isError = true
          this.message = this.messages.updateError

          return
        }

        this.previousOtp = otp

        const hasOtpSession = await this.hasOtpSession()

        if (!hasOtpSession) {
          return this.$router.push({ name: 'login' })
        }

        const { user, tries } = await this.verifyOtpCode(otp)
        
        if (tries === 0) {
          this.hasFailedVerification = true
          this.isError = true
          this.message = this.messages.failedError
          
          return
        }

        const triesLeft = this.numberOfTries - parseInt(tries)
        this.triesLeft = triesLeft

        if (triesLeft === 1) {
          this.isError = true
          this.message = this.messages.lastAttempt

          return
        }

        if (triesLeft > 1) {
          console.log(this.messages.failedAttemptError)
          this.isError = true
          this.message = `${this.messages.failedAttemptError} ${this.messages.attemptsLeft} ${this.triesLeft}.`

          return
        }

        if (!user) {
          this.hasFailedVerification = true
          this.isError = true
          this.message = this.messages.failedError
          
          return
        }

        return this.navigateToDefaultRoute()
      } catch (error) {
        console.log(error)
        this.isError = true
        this.message = this.messages.error
      }
    },
    handleVerificationChange () {
      if (this.isError && this.triesLeft) {
        this.isError = false
        this.message = (this.triesLeft === 1) ? this.messages.lastAttempt : `${this.messages.attemptsLeft} ${this.triesLeft}.`

        return
      }
    },
    clearError () {
      this.isError = false
    }
  },
  async mounted () {
    try {
      const hasOtpSession = await this.hasOtpSession()

      if (!hasOtpSession) {
        return this.navigateToDefaultRoute()
      }
    } catch(error) {
      return this.$router.push({ name: 'login' })
    }
  },
  beforeDestroy () {
    this.$off('login', this.processVerificationCode)
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.login {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $brand-gradient;
}

</style>