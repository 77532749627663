import { getUser } from '@/services/auth.service'

const organisation = {
  namespaced: true,
  state: {
    organisationId: String,
    organisations: [],
    users: []
  },
  mutations: {
    SET_ORGANISATIONS (state, organisations) {
      state.organisations = organisations
    },
    SET_USERS (state, users) {
      state.users = users
    },
    ADD_USER (state, user) {
      state.users.push(user)
    },
    UPDATE_USER (state, user) {
      state.users = state.users.map(u => (u = u.id === user.id ? user : u))
    },
    ADD_ORGANISATION (state, organisation) {
      state.organisations.push(organisation)
    },
    EDIT_ORGANISATION (state, organisation) {
      const orgs = state.organisations
      const orgIndex = orgs.findIndex(({id}) => id === organisation.id)
      if (orgIndex !== -1) {
        orgs[orgIndex] = {...orgs[orgIndex], ...organisation}
        console.log(orgs)
        state.organisations = [...orgs]
      } else
        state.organisations.push(organisation)
    }
  },
  actions: {
    async fetchOrganisations ({ commit }) {
      try {
        const { data } = await this.$api.fetchAllOrganisations()
        commit('SET_ORGANISATIONS', data)
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchUsers ({ commit }) {
      try {
        const organisationId = getUser().organisationId
        const { data } = await this.$api.fetchUsersForOrganisation(organisationId)
        commit('SET_USERS', data)
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async createNewUser ({ commit, dispatch }, { user, organisationId }) {
      try {
        console.log('CreateUser', user)
        user.email.trim()
        const { data } = await this.$api.addUserToOrganisation(user, organisationId)
        commit('ADD_USER', data)
        dispatch('user/addUser', data, { root: true })
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateUser ({ commit, dispatch }, { user, organisationId }) {
      try {
        const { data } = await this.$api.updateUserOrganisation(user, organisationId)
        commit('UPDATE_USER', data)
        dispatch('user/updateUser', data, { root: true })
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async createNewOrganisation ({ commit, dispatch }, organisation) {
      try {
        const { data } = await this.$api.createOrganisation(organisation)
        commit('ADD_ORGANISATION', data)
        dispatch('user/addUsers', data.users, { root: true })
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateOrganisation ({ commit, dispatch }, organisation) {
      try {
        const { data } = await this.$api.updateOrganisation(organisation)
        commit('EDIT_ORGANISATION', data)
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },


  getters: {
    organisations: state => state.organisations,
    users: state => state.users
  }
}

export default organisation
