<template>
  <div class="upload-container">
    <SectionHeader title="View AR Audits">
      <template v-slot:searchbar>
        <div class="search-bar field no-margin">
          <span class="icon input-icon">
            <i class="fas fa-search"></i>
          </span>
          <input
            type="text"
            class="input has-icon"
            placeholder="Search"
            v-model="searchTerm"
            @input="search"
          />
        </div>
      </template>
    </SectionHeader>
    <div style="flex: 1">
      <div class="dateFilter">
        Uploaded from:
        <input type="date" name="search" v-model="dateRange.start" />
        To:
        <input type="date" name="search" v-model="dateRange.end" />
      </div>
    </div>
    <div class="list__options">
      <label for="page-size"> Results per page: </label>&nbsp;
      <input
        class="input"
        type="number"
        step="5"
        name="page-size"
        v-model.number="limit"
      />
    </div>
    <div class="ehcp-list">
      <div class="table-wrapper">
        <table class="table">
          <thead>
            <tr>
              <th @click="sortBy('internalId')">*C/YP ID</th>
              <th @click="sortBy('keyStage')">Key Stage</th>
              <th @click="sortBy('primaryNeed')">Primary Need</th>
              <th @click="sortBy('settingName')">Setting Name</th>
              <th @click="sortBy('settingType')">Setting Type</th>
              <th>Review Date</th>
              <th @click="sortBy('currentSocialCare')">Current Social Care</th>
              <th @click="sortBy('caseOfficer')">SEN Case Officer</th>
              <th @click="sortBy('teamRegion')">Team / Region</th>
              <th @click="sortBy('historical')">Is Historical</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(plan, index) in paginatedAnnualReviews" :key="index">
              <td>{{ plan.internalId }}</td>
              <td>{{ plan.keyStage }}</td>
              <td>{{ plan.primaryNeed }}</td>
              <td>{{ plan.settingName }}</td>
              <td>{{ plan.settingType }}</td>
              <td>{{ plan.finalisedDate | formatDate }}</td>
              <td>{{ plan.currentSocialCare }}</td>
              <td>{{ plan.caseOfficer }}</td>
              <td>{{ plan.teamRegion }}</td>
              <td>{{ plan.historical }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="list__controls">
      <vue-paginate-al
        :totalPage="totalPages"
        :currentPage="currentPage"
        @btnClick="goToFunction"
        customActiveBGColor="#278a78"
        :pageRange="4"
        nextText=">"
        prevText="<"
      >
      </vue-paginate-al>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/SectionHeader";
import DateFilter from "@/_mixins/date-filter.mixin";
import { mapGetters } from "vuex";

import fuzzysort from "fuzzysort";
import isBefore from "date-fns/isBefore/index";
import isAfter from "date-fns/isAfter/index";

export default {
  name: "Plans",
  mixins: [DateFilter],
  components: { SectionHeader },
  data() {
    return {
      sortKey: "updatedAt",
      limit: 10,
      offset: 0,
      orderToggle: false,
      lastPage: 0,
      dateRange: {
        start: null,
        end: null,
      },
      searchTerm: null,
      search: null,
    };
  },
  watch: {
    offset(newValue, oldValue) {
      this.offset = newValue;
    },
    limit(newValue, oldValue) {
      this.limit = newValue;
    },
  },
  computed: {
    ...mapGetters({ _plans: "plan/plans" }),
    plans() {
      return [...this._plans].sort((a, b) =>
        a.updatedAt > b.updatedAt ? -1 : 1
      );
    },
    orderedAndFiltered() {
      const keys = this.sortKey.split(".");
      let assignments = [...this.plans];

      if (this.dateRange.start) {
        assignments = assignments.filter(({ createdAt }) =>
          isAfter(
            new Date(createdAt.slice(0, 10)),
            new Date(this.dateRange.start)
          )
        );
      }

      if (this.dateRange.end) {
        assignments = assignments.filter(({ createdAt }) =>
          isBefore(
            new Date(createdAt.slice(0, 10)),
            new Date(this.dateRange.end)
          )
        );
      }

      if (this.searchTerm && this.searchTerm.length) {
        assignments = fuzzysort
          .go(this.searchTerm, assignments, {
            threshold: -1000,
            all: true,
            keys: ["internalId", "caseOfficer", "teamRegion"],
          })
          .map((a) => a.obj);
      }

      assignments.sort((a, b) => {
        // console.log({ keys })
        for (let i = 0; i < keys.length; i++) {
          a = a[keys[i]];
          b = b[keys[i]];
        }
        // console.log({ a, b, toggle: this.orderToggle })
        return this.orderToggle ? (a < b ? -1 : 1) : a > b ? -1 : 1;
      });

      return assignments;
    },
    totalPages() {
      return Math.ceil(this.orderedAndFiltered.length / this.limit);
    },
    currentPage() {
      return this.offset / this.limit + 1;
    },
    paginatedAnnualReviews() {
      const limit = this.offset + this.limit;
      console.log("data1", this.orderedAndFiltered.slice(this.offset, limit));
      return this.orderedAndFiltered.slice(this.offset, limit);
    },
  },
  methods: {
    previousPage() {
      this.offset = this.offset - this.limit < 0 ? 0 : this.offset - this.limit;
      console.log("page will be", this.currentPage);
    },
    nextPage() {
      this.offset =
        this.offset + this.limit > this.orderedAndFiltered.length
          ? 0
          : this.offset + this.limit;
      console.log("page will be", this.currentPage);
    },
    goToPage(page) {
      this.offset = page * this.limit;
      console.log("page will be", page);
    },
    sortBy(toSort) {
      this.offset = 0;
      this.orderToggle = this.sortKey === toSort ? !this.orderToggle : true;
      console.log({ orderToggle: this.orderToggle });
      // this.sortKey = 'createdAt'
      this.sortKey = toSort;
    },
    goToFunction(value) {
      console.log(value);
      this.offset =
        value > this.lastPage
          ? this.offset + this.limit
          : this.offset - this.limit;
      this.lastPage = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/sections.scss";

.upload-container {
  width: 100%;
  &__dropbox {
    align-items: center;
    border-radius: $border-radius;
    border: 1px dashed lightgrey;
    cursor: pointer;
    display: flex;
    font-size: 1.25rem;
    height: 100px;
    justify-content: center;
    margin: 1rem;
    padding: 1rem;
  }
  .button-row {
    display: flex;
    flex-direction: row-reverse;
  }
}

.is-active {
  background: #42ab983b;
}

.control {
  display: flex;
  place-items: center;
  place-content: center;
  width: 2rem;
  height: 2rem;
  border: 1px solid #eeedec;
  border-radius: 4px;
  background: transparent;
  box-shadow: none;
  transition: box-shadow 200ms;
  font-weight: 600;
  &:hover {
    box-shadow: 0 4px 8px #eeedec;
  }
}

.list__options {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: baseline;
  .input {
    max-width: 64px;
    padding-left: 1rem;
    margin-left: 0.5rem;
    font-weight: 600;
  }
}

.list__controls {
  grid-column: 1 / 3;
  justify-content: center;
  margin-bottom: 20px;
}

.list__controls,
.pagination-control {
  display: flex;
}
.pagination-control .control {
  border: none;
}

.link-hover:hover {
  cursor: pointer;
  text-decoration: underline;
}

.current-page {
  color: blue;
  text-decoration: underline;
}

th {
  // color: red;
  cursor: pointer;
}

// /deep/ .pagination li a {
//   color: black !important;
// }

// /deep/ .pagination li:hover {
//   a {
//     text-decoration: underline;
//   }
// }

// /deep/ .pagination .active a {
//   color: white !important;
// }
</style>
