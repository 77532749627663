const c = {
  outstanding: '#00B9C1',
  good: '#49E5A6',
  requiresImprovement: '#FFCB4B',
  inadequate: '#E76D93',
  notApplicable: '#D4D6E4',
  essential: '#ABA2CE',
  optional: '#FF9328'
}

const labelOrder = ['Gold', 'Silver', 'Bronze']
  
const names = {
  not_applicable: {
    text: 'Not Applicable',
    color: c.notApplicable,
    order: 5
  },
  outstanding: {
    text: 'Outstanding',
    color: c.outstanding,
    order: 0
  },
  good: {
    text: 'Good',
    color: c.good,
    order: 1
  },
  requires_improvement: {
    text: 'Requires Improvement',
    color: c.requiresImprovement,
    order: 2
  },
  inadequate: {
    text: 'Inadequate',
    color: c.inadequate,
    order: 3
  },
  essential: {
    text: 'Clearly Includes',
    color: c.essential,
    order: 4
  }
}
  
const colorLookup = {
  Gold: '#FFCB4B',
  Silver: '#D4D6E4',
  Bronze: '#FF9328'
}

const labelDescription = {
  Gold: {
    label: 'Gold',
    colour: '#FFCB4B',
    order: 0
  },
  Silver: {
    label: 'Silver',
    colour: '#D4D6E4',
    order: 1
  },
  Bronze: {
    label: 'Bronze',
    colour: '#FF9328',
    order: 2
  }
}

  export { labelOrder, labelDescription, colorLookup, names }