import {
  getUser,
  getAccessToken,
  setUser,
  setAccessToken,
  logout,
} from '@/services/auth.service'

const user = {
  namespaced: true,
  state: {
    token: getAccessToken(),
    user: getUser(),
    users: [],
    resetRequested: false,
    validResetToken: null,
   
  },
  mutations: {
    SET_USER (state, user) {
      console.log('SET USER',user)
      console.log('USER', state.user)
      setUser({ ...user })
      state.user = getUser()
    },
    SET_TOKEN (state, token) {
      setAccessToken(token)
      state.token = getAccessToken()
    },
    SET_USERS (state, users) {
      state.users = users
    },
    ADD_USER (state, user) {
      state.users.push(user)
    },
    ADD_USERS (state, users) {
      state.users.push(...users)
    },
    UPDATE_USER(state, user) {
      const index = state.users.findIndex(u => u.id === user.id);
      if (index !== -1) {
        // Update the user at the found index
        // this way we are mutating the original array which should cause 
        state.users.splice(index, 1, user);
      }
    },
    
    SET_LOGOUT (state) {
      logout()
      state.user = null
      state.token = null
      state.users = []
    },
    SET_RESET_REQUESTED (state, requested) {
      state.resetRequested = requested
    },
    SET_RESET_TOKEN_VALID (state, requested) {
      state.validResetToken = requested
    },
  },
  actions: {
    async fetchUsers ({ commit }) {
      try {
        const { data } = await this.$api.fetchAllUsers()
        commit('SET_USERS', data)
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async login ({ commit }, { email, password }) {
      try {
        const { user, tokens } = await this.$api.loginWithEmailAndPassword(email, password)
        
        if (user && !user.twoFactorAuth && tokens) {
          commit('SET_USER', user)
          commit('SET_TOKEN', tokens.access.token)
        }
        
        return { user } 
      } catch (error) {
        return error
      }
    },
    async verifyOtpCode ({ commit }, submittedOtpCode) {
      try {
        const { user, tokens, tries } = await this.$api.verifyOtpCode(submittedOtpCode)

        if (tokens && user) {
          commit('SET_USER', user)
          commit('SET_TOKEN', tokens.access.token)
        }

        return { user, tries }
      } catch (error) {
        throw error
      }
    },
    /**
     * @description Resend OTP code
     * @param {Object} context
     * @param {Object} context.commit
     * @returns {Promise}
     * @memberof Auth
     */
    async resendOtpCode ({ commit }) {
      try {
        const { data } = await this.$api.resendOtpCode()

        return data
      } catch (error) {
        throw error
      }
    },
    async hasOtpSession ({ commit }) {
      try {
        const { data } = await this.$api.hasOtpSession()

        return data
      } catch (error) {
        throw error
      }
    },
    addUser ({ commit }, user) {
      commit('ADD_USER', user)
    },
    addUsers ({ commit }, users) {
      commit('ADD_USERS', users)
    },
    updateUser ({ commit }, user) {
      commit('UPDATE_USER', user)
    },
    logout ({ commit, dispatch }) {
      commit('SET_LOGOUT')
      dispatch('assignment/setCurrentAssignment', null, { root: true })
    },
    async requestPasswordReset ({ commit }, { email }) {
      try {
        await this.$api.requestPasswordReset(email)
        commit('SET_RESET_REQUESTED', true)
        return Promise.resolve()
      } catch (err) {
        commit('REQ_ERROR', err)
        return Promise.reject(err)
      }
    },
    async verifyToken ({ commit }, { token }) {
      try {
        const resp = await this.$api.verifyToken(token)
          .catch(e => commit('SET_RESET_TOKEN_VALID', false))
        
        if (resp.data.successInd) {
          commit('SET_RESET_TOKEN_VALID', resp.data.successInd)
        }
        return Promise.resolve()
      } catch (err) {
        commit('REQ_ERROR', err)
        return Promise.reject(err)
      }
    },
    async resetPassword ({ commit }, { token, password }) {
      try {
        await this.$api.resetPassword(token, password)
        return Promise.resolve()
      } catch (err) {
        commit('REQ_ERROR', err)
        return Promise.reject(err)
      }
    },

    async updateTwoFactorAuthOnUser ({commit, dispatch} , user) {
      try{
        await this.$api.updateUserTwoFactorAuth(user)
        commit('UPDATE_USER', user)
        

      }
      catch(err) {
        commit('REQ_ERROR', err)
        return Promise.reject(err)
      }
  
    },
  },
  getters: {
    role: state => state.user.role || null,
    token: state => state.token,
    user: state => state.user,
    users: state => state.users,
    validResetToken: state => state.validResetToken,
  }
}

export default user
