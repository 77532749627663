<template>
  <div class="passwordReset">
    <PasswordResetForm @password-reset="processPasswordReset" @clear-error="clearError" :error="error" :submitMessage="submitMessage"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PasswordResetForm from '@/components/forms/PasswordResetEntry.vue'

export default {
  name: 'PasswordResetEntry',
  components: { PasswordResetForm },
  data () {
    return {
      error: null,
      submitMessage: null
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    token () {
      return this.$route.params.token || null
    }
  },
  methods: {
    ...mapActions({ resetPassword: 'user/resetPassword', verifyToken: 'user/verifyToken'}),
    async processPasswordReset (payload) {
      const { passwordOne } = payload

      try {
        await this.resetPassword({ token: this.token, password: passwordOne })
        this.submitMessage = 'Password reset successful. Please return to login.'
      } catch (err) {
        if(err.response.data.message === 'password matches') {
          return this.error = 'Error: Your new password matches your old password. Please choose a different password that is distinct from your previous one for security reasons.'
        }
        this.error = err
      }
    },
    clearError () {
      this.error = null
    }
  },
  async mounted () {
    setTimeout(() => {
      this.verifyToken({ token: this.token })
    }, 500)
  },
  beforeDestroy () {
    this.$off('password-reset', this.processPasswordReset)
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
.passwordReset {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $brand-gradient;
}
</style>
