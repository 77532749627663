import { getUser } from '@/services/auth.service'
// import _ from 'lodash';

// In theme 6 they can change the questions based on answer questionIndex 3 meaning we may not update the correct answer in the
// mapTheme function. So this function remove the criteria if the answer is questionIndex 3
const filterThemeSixCriteria = (criteria, answerObject) => {
  return criteria.filter((item) => {

    const isAnswerIteration = item.iteration === answerObject.answer.iteration

    // If not an iteration question we still need the answer so return early
    if (!isAnswerIteration) {
      return true
    }
    
    if (item.questionIndex === 4 || item.questionIndex === 5) {
      return false
    }

    return true
  })
}

// A function to sort theme 6 answers
const sortTheme6 = (a, b) => {
  if (a.iteration === b.iteration) {
    return a.questionIndex > b.questionIndex ? 1 : -1
  } 

  if (a.iteration && b.iteration) {
    return a.iteration > b.iteration ? 1 : -1
  }

  return 1
}

// A function to add an answer to a theme object with an exception to handle theme 6 and iteration questions
const addAnswerToThemeObject = (themeObject, answerObject) => {
  if (themeObject.theme !== answerObject.theme) {
    return themeObject
  }

  const isIteration = answerObject.answer.hasOwnProperty('iteration')

  if (isIteration) {

    const existingIterationIndex = themeObject.criteria.findIndex(existing => existing.iteration === answerObject.answer.iteration && existing.questionIndex === answerObject.answer.questionIndex)

    if (existingIterationIndex > -1) {
      themeObject.criteria[existingIterationIndex] = answerObject.answer

      return themeObject
    } 
      
    themeObject.criteria.push(answerObject.answer)

    return themeObject
  } 

  const existingAnswerIndex = themeObject.criteria.findIndex(existing => existing.questionIndex === answerObject.answer.questionIndex)

  if (existingAnswerIndex > -1) {
    themeObject.criteria[existingAnswerIndex] = answerObject.answer

    return themeObject
  }
    
  themeObject.criteria.push(answerObject.answer)

  return themeObject
}

const review = {
  namespaced: true,
  state: {
    answers: Array,
    assignmentId: String,
    reviewId: String,
    internalId: String,
    createdAt: Date,
    updatedAt: Date,
    completedAt: Date,
    complete: Boolean,
    outcome: String,
    actions: Array,
    requestStart: Date,
    requestEnd: Date,
    error: Error,
    reviews: Array,
    grades: {
      Gold: { min: 15, max: 100 },
      Silver: { min: 8, max: 14.999 },
      Bronze: { min: 0, max: 7.999 }
    }
  },
  mutations: {
    REQUEST_START (state) {
      state.requestStart = new Date()
    },
    REQUEST_END (state) {
      state.requestEnd = new Date()
    },
    REQUEST_ERROR (state, error) {
      state.error = error
    },
    SET_REVIEW_START (state, options) {
      state.assignmentId = options.assignmentId
      state.reviewId = options.id
      state.answers = options.answers
      state.complete = false,
      state.actions = null
      state.createdAt = options.createdAt
      state.updatedAt = options.updatedAt
      state.internalId = options.assignment.plan.internalId
    },
    SET_REVIEWS (state, reviews) {
      state.reviews = reviews 
    },
    SET_REVIEW_END (state) {
      state.complete = true
      state.updatedAt = new Date()
    },
    SET_REVIEW_UPDATED (state, review) {
      // const cloned = _.cloneDeep(review);

      state.updatedAt = review.updatedAt
      state.answers = review.answers
      state.actions = review.actions
    },
    SET_ACTIONS (state, actions) {
      state.actions = actions 
    },
    ADD_REVIEW_ANSWER (state, answerObject) {
      // Init theme if the theme does not exist
      const themeMatch = state.answers.some(item => item.theme === answerObject.theme)

      if (!themeMatch) {
        state.answers.push({
          theme: answerObject.theme,
          comments: null,
          criteria: []
        })
      }

      // Check if it is an iteration question
      const isIterationAnswer = answerObject.answer.hasOwnProperty('iteration')
      const isThemeSixAnswer = answerObject.theme === 6
      const themeSixIndex = state.answers.findIndex(item => item.theme === 6)

      // MF NOTE: The order in how we update state is important.
      // Handle theme 6 non iteration questions first directly in the state
      // then cache state.answers in a variable and handle theme 6 iteration questions and sorting of theme 6 iteration answers
      // then map the answer to the correct theme object

      // TODO: MF It would make sense to refactor the handling of theme 6 so that the object is added to the db as a whole

      // Handle theme 6 non iteration questions
      if (isThemeSixAnswer && !isIterationAnswer) {

        // If theme 6 and questionIndex 0 is "No" clear the criteria but keep comments
        if (answerObject.answer.questionIndex === 0 && answerObject.answer.score === 1) {
          state.answers[themeSixIndex] = { ...state.answers[themeSixIndex], ...{ criteria: [] } }
        }

        // If theme 6 and questionIndex 1 then filter array based on this value
        if (answerObject.answer.questionIndex === 1) {
          const criteria = state.answers[themeSixIndex].criteria
          
          // This pure function can't be moved out of scope because it relies on the state
          const filteredNumberOfIterations = criteria.reduce((previous, current) => {
            if (!current.hasOwnProperty('iteration') || current.iteration <= answerObject.answer.text) {
              previous.push(current);
            }

            return previous;
          }, []);

          state.answers[themeSixIndex] = { ...state.answers[themeSixIndex], ...{ criteria: filteredNumberOfIterations } }
        }
      }
      
      const answers = [...state.answers]

      // Handle theme 6 iteration questions
      if (isThemeSixAnswer && themeSixIndex) {
        answers[themeSixIndex].criteria = answers[themeSixIndex].criteria.sort(sortTheme6)

        // Check if question 3 updated
        const isQuestionThreeUpdated = (answerObject.answer.iteration) && answerObject.answer.questionIndex === 3

        if (isQuestionThreeUpdated) {
          const themeSixCriteria = answers[themeSixIndex].criteria
          const filteredThemeSixCriteria = filterThemeSixCriteria(themeSixCriteria, answerObject)
          // Filter out answers 4/5 if question 3 updated
          answers[themeSixIndex].criteria = filteredThemeSixCriteria
        }
      }

      const mappedAnswers = [...answers.map((themeObject) => addAnswerToThemeObject(themeObject, answerObject))]

      return state.answers = mappedAnswers
    },
    SET_THEME_COMMENT (state, comment) {
      state.answers = state.answers.map(answer => {
        if (comment.theme === answer.theme) {
          answer.comments = comment.text
        }
        return answer
      })
    },
    SET_REVIEW_COMPLETED (state) {
      state = { ...state}
      state.complete = true
    },

  
  },
  actions: {
    async startReview ({ commit }, { assignmentId, plan }) {
      commit('REQUEST_START')
      try {
        const userId = getUser().id
        const organisationId = getUser().organisationId
        const { data: review } = await this.$api.createReviewForOrganisation({ userId, organisationId, assignmentId, answers: [] }, organisationId)
        commit('SET_REVIEW_START', {...review, assignment: { plan } })
        commit('REQUEST_END')
        Promise.resolve()
      } catch (err) {
        commit('REQUEST_ERROR', err)
        Promise.reject(err)
      }
    },
    async continueReview ({ commit }, review) {
      commit('SET_REVIEW_START', review)
    },

    async updateReviewActions({commit, state}, actions) {
      const actionsArray = actions

      try{
        const organisationId = await getUser().organisationId;
        commit("SET_REVIEW_UPDATED", {...state, actionsArray})
        
        const actions = (state.actions.length > 0) ? state.actions.toString() : null 
        
        await this.$api.updateReviewForOrganisation({ reviewId: state.reviewId, actions }, organisationId)
      }
      catch(error) {
        commit('REQUEST_ERROR', error)

        return Promise.reject(error)
      }
    },
    async submitAnswer ({ commit, state }, answer) {
      try {
        const organisationId = getUser().organisationId

        const { data: review } = await this.$api.updateReviewForOrganisation({ 
          reviewId: state.reviewId, 
          answer,
        }, organisationId)


        
        commit('SET_REVIEW_UPDATED', review)
        commit('REQUEST_END')

        return Promise.resolve(true)
      } catch (err) {

        commit('REQUEST_ERROR', err)

        return Promise.reject(err)
      }
    },
    async addComment ({ commit, state }, comment) {
      commit('REQUEST_START')

      try {
        const organisationId = getUser().organisationId
        // const actions = (state.actions && state.actions.length > 0) ? state.actions.toString() : null 

        const { data: review } = await this.$api.updateReviewForOrganisation({ 
          reviewId: state.reviewId, 
          comment,
          // actions 
        }, organisationId)
        
        commit('SET_REVIEW_UPDATED', review)
        commit('REQUEST_END')

        Promise.resolve()
      } catch (err) {
        commit('REQUEST_ERROR', err)
        Promise.reject(err)
      }
      
    },
    async setReviewComplete ({ commit, dispatch, state }) {
      commit('REQUEST_START')
      try {
        const organisationId = getUser().organisationId
        const { data: review } = await this.$api.submitReviewForOrganisation({ reviewId: state.reviewId }, organisationId)
        
        dispatch('assignment/setCurrentAssignment', null, { root: true })
        
        commit('SET_REVIEW_COMPLETED', review)
        commit('REQUEST_END')

        Promise.resolve()
      } catch (err) {
        commit('REQUEST_ERROR', err)

        Promise.reject(err)
      }
    },
    // Action to refresh a review
    async refreshReview ({commit, dispatch}, id) { 
      try {
        const organisationId = getUser().organisationId
        const { data } = await this.$api.fetchReviewsForOrganisation(organisationId);

        const review = data.find(el => el.id === id )

        if (!review) {
          return Promise.reject(err)
        }

        commit("SET_REVIEW_UPDATED", review)

        return Promise.resolve()
      } catch (error) {
        return Promise.reject(err)
      }
    },
    async fetchReviews ({commit, dispatch}, id) {
      const organisationId = getUser().organisationId
      const { data } = await this.$api.fetchReviewsForOrganisation(organisationId);
      
      const foundData = data.find(el => el.id === id )

      const foundActions = (foundData && foundData.hasOwnProperty('actions') && foundData.actions) 
      
      const actions = (foundActions)
        ? foundData.actions.split(",")
        : []

      commit("SET_ACTIONS", actions)
    }


  },
  getters: {
    answers: state => state.answers,
    assignmentId: state => state.assignmentId,
    reviewId: state => state.reviewId,
    internalId: state => state.internalId,
    actions: state => state.actions,
    reviews: state => state.reviews
  }
}

export default review
