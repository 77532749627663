import { surveys } from '@/mocks/surveys'

const feedback = {
  namespaced: true,
  state: {
    surveys: [...surveys],
    feedback: [],
    updatedAt: null,
    updatePeriodMS: 1 * 60 * 1000
  },
  mutations: {
    SET_FEEDBACK (state, feedback) {
      state.feedback = [...feedback]
      state.updatedAt = new Date()
    },
    REFRESH (state) {
      state.surveys = [...surveys]
    }
  },
  actions: {
    async getAllFeedbackData ({ commit }, organisationId) {
      try {
        const { data } = await this.$api.fetchFeedbackForOrganisation(organisationId)
        commit('SET_FEEDBACK', data)
        return data
      } catch (err) {
        return err
      }
    }
  },
  getters: {
    surveys: state => state.surveys,
    feedback: state => state.feedback,
    feedbackById: state => id => state.feedback.find(f => f.id === id),
    feedbackByReviewId: state => reviewId => state.feedback.find(f => f.reviewId === reviewId),
    updatedAt: state => state.updatedAt,
    needsUpdating: state => (new Date() - state.updatedAt) >= state.updatePeriodMS
  }
}

export default feedback
