import Vue from 'vue'
import { getUser } from '@/services/auth.service'

const assignment = {
  namespaced: true,
  state: {
    assignments: [],
    reviews: [],
    assignmentsForCurrentUser: [],
    currentAssignment: null,
    fetchStart: null,
    fetchEnd: null,
    error: null
  },
  mutations: {
    UPLOAD_START (state) {
      state.fetchStart = Date.now()
    },
    UPLOAD_END (state) {
      state.fetchEnd = Date.now()
    },
    UPLOAD_ERROR (state, error) {
      state.error = error
    },
    FETCH_START (state) {
      state.fetchStart = Date.now()
    },
    FETCH_END (state) {
      state.fetchEnd = Date.now()
    },
    FETCH_ERROR (state, error) {
      state.error = error
    },
    SET_ASSIGNMENTS (state, assignments) {
      Vue.set(state, 'assignments', [...assignments])
    },
    ADD_ASSIGNMENTS (state, assignments) {
      Vue.set(state, 'assignments', [...state.assignments, ...assignments])
    },
    SET_ASSIGNMENTS_FOR_CURRENT_USER (state, assignments) {
      Vue.set(state, 'assignmentsForCurrentUser', [...assignments])
    },
    SET_EXISTING_REVIEWS (state, reviews) {
      Vue.set(state, 'reviews', [...reviews])
    },
    SET_CURRENT_ASSIGNMENT (state, assignment) {
      Vue.set(state, 'currentAssignment', assignment)
    }
  },
  actions: {
    async archive({ commit }, assignmentId) {
      commit('FETCH_START')
      try {
        const userId = getUser().id
        const organisationId = getUser().organisationId

        await this.$api.archiveAssignment(organisationId, assignmentId)

        const { data } = await this.$api.fetchAssignmentsForOrganisation(organisationId)
        const { data: reviews } = await this.$api.fetchReviewsForOrganisation(organisationId)
        commit('SET_ASSIGNMENTS', data)
        commit('SET_EXISTING_REVIEWS', reviews)

        const { data: data2 } = await this.$api.fetchAssignmentsForUserInOrganisation(organisationId, userId)
        commit('SET_ASSIGNMENTS_FOR_CURRENT_USER', data2)

        commit('FETCH_END')
      } catch (error) {
        commit('FETCH_ERROR', error)
        return Promise.reject(error)
      }
    },

    async createAssignments ({ commit }, assignments) {
      commit('UPLOAD_START')
      try {
        const organisationId = getUser().organisationId
        const { data } = await this.$api.createAssignmentsForOrganisation(assignments, organisationId)
        commit('ADD_ASSIGNMENTS', data)
        commit('UPLOAD_END')
        return Promise.resolve()
      } catch (error) {
        commit('UPLOAD_ERROR', error)
        return Promise.reject(error)
      }
    },
    async fetchAssignments ({ commit }) {
      commit('FETCH_START')
      try {
        const organisationId = getUser().organisationId
        const { data } = await this.$api.fetchAssignmentsForOrganisation(organisationId)
        const { data: reviews } = await this.$api.fetchReviewsForOrganisation(organisationId)
        commit('SET_ASSIGNMENTS', data)
        commit('SET_EXISTING_REVIEWS', reviews)
        commit('FETCH_END')
      } catch (error) {
        commit('FETCH_ERROR', error)
        return Promise.reject(error)
      }
    },
    async fetchAssignmentsForCurrentUser ({ commit }) {
      commit('FETCH_START')
      try {
        const userId = getUser().id
        const organisationId = getUser().organisationId
        const { data } = await this.$api.fetchAssignmentsForUserInOrganisation(organisationId, userId)
        const { data: reviews } = await this.$api.fetchReviewsForOrganisation(organisationId)
        commit('SET_ASSIGNMENTS_FOR_CURRENT_USER', data)
        commit('SET_EXISTING_REVIEWS', reviews)
        commit('FETCH_END')
      } catch (error) {
        commit('FETCH_ERROR', error)
        return Promise.reject(error)
      }
    },
    setCurrentAssignment ({ commit }, assignment) {
      const newAssignment = (assignment) ? assignment : null
      commit('SET_CURRENT_ASSIGNMENT', newAssignment)
    }
  },
  getters: {
    assignments: state => [...state.assignments],
    reviews: state => [...state.reviews],
    completedReviews: state => [...state.reviews].filter(review => review.completedAt),
    assignmentsForCurrentUser: state => [...state.assignmentsForCurrentUser],
    currentAssignment: state => state.currentAssignment
  }
}

export default assignment
