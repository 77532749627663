import { themes } from '@/mocks/criteria'

const data = {
  namespaced: true,
  state: {
    themes: [...themes]
  },
  mutations: {
    REFRESH (state) {
      state.themes = [...themes]
    }
  },
  getters: {
    themes: state => state.themes
  }
}

export default data
