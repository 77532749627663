<template>
  <div class="home"/>
</template>

<script>
import DefaultRouteMixin from '@/_mixins/default-route.mixin'
import { getUser } from '@/services/auth.service'

export default {
  name: 'home',
  mixins: [DefaultRouteMixin],
  mounted () {
    if (!getUser()) {
      this.$router.push('/login')
      return
    }
  
    this.navigateToDefaultRoute()
  }
}
</script>